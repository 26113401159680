/* colors */
:root {
  --theme: #D5001C;
  --theme-overlay: rgba(213, 0, 28, 0.8);
  --black-overlay: rgba(0, 0, 0, 0.3);
  --theme-sidebar: #f8f9fa;
  --white: #FFFFFF;
  --xlight-grey: #EFEFEF;
  --light-grey: #e3e4e5;
  --med-grey: #626669;
  --dark-grey: #323639;
  --near-black: #191f22;
  --black: #000000;
  --success-light: #e5f3e7;
  --success: #018a16;
  --warning-light: #fff5e5;
  --warning: #ff9b00;
  --error-light: #fae6e6;
  --error: #e00000;
  --info-light: #e5eff8;
  --info: #0061bd;
  --clear: transparent;
}

/* Typography */
/* @font-face {
  font-family: 'RegularFont';
  src: url('../fonts/porsche-next-regular.woff2');
}

@font-face {
  font-family: 'ThinFont';
  src: url('../fonts/porsche-next-thin.woff2');
}

@font-face {
  font-family: 'SemiBoldFont';
  src: url('../fonts/porsche-next-semi-bold.woff2');
}

@font-face {
  font-family: 'BoldFont';
  src: url('../fonts/porsche-next-bold.woff2');
} */
@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/porsche-next-w-cy-bold.woff2') format('woff2');
  unicode-range: U+0400-04FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/porsche-next-w-cy-regular.woff2') format('woff2');
  unicode-range: U+0400-04FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/porsche-next-w-cy-semi-bold.woff2') format('woff2');
  unicode-range: U+0400-04FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/porsche-next-w-gr-bold.woff2') format('woff2');
  unicode-range: U+0370-03FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/porsche-next-w-gr-regular.woff2') format('woff2');
  unicode-range: U+0370-03FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/porsche-next-w-gr-semi-bold.woff2') format('woff2');
  unicode-range: U+0370-03FF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/porsche-next-w-la-bold.woff2') format('woff2');
  unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF, U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/porsche-next-w-la-regular.woff2') format('woff2');
  unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF, U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
  font-display: swap
}

@font-face {
  font-family: Porsche Next;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/porsche-next-w-la-semi-bold.woff2') format('woff2');
  unicode-range: U+0020-007F, U+0080-00FF, U+0100-017F, U+0180-024F, U+0250-02AF, U+02B0-02FF, U+0300-036F, U+0E00-0E7F, U+1E00-1EFF, U+2000-206F, U+2070-209F, U+20A0-20CF, U+2100-214F, U+2150-218F, U+2190-21FF, U+2200-22FF, U+25A0-25FF, U+2600-26FF, U+FB00-FB4F, U+FE70-FEFF;
  font-display: swap
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Porsche Next', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;
  line-height: 1.25;
  letter-spacing: normal;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
  font-family: 'Porsche Next', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;
}

p {
  font-family: 'Porsche Next', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;
  /* hyphens: auto; */
  font-weight: 400;
  /* overflow-wrap: break-word */
}

b,
strong,
.strong {
  font-weight: 600;
  font-family: 'Porsche Next', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;

}

div,
button {
  font-family: 'Porsche Next', 'Arial Narrow', Arial, 'Heiti SC', SimHei, sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  font-size: 16px;
  line-height: 1.4;
  font-family: 'Porsche Next', Helvetica, Arial, sans-serif;
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  width: 100%;
  /* row-gap: 50px; */
}
body:has(#dashboard-layout) {
  max-width:100% !important;
}
/* 12 cols */
.grid-item {
  grid-column: 3 / 15;
}

/* 16 cols */
.grid-item-full {
  grid-column: span 16;
}
.hfull {
  min-height:70vh;
}

/* 8 cols */
.grid-item-narrow {
  grid-column: 5 / 13;
}

.passcode-box {
  max-width: 600px;
}

#main-layout {
  margin: 0 auto;
}

.hidden {
  height: 0;
  overflow: hidden;
}

.logo {
  max-width: 124px;
}

.nowrap {
  white-space: nowrap;
}

.fs-xxxl {
  font-size: 60px;
}

.fs-xx-l {
  font-size: 48px;
}

.fs-xxl {
  font-size: 38px;
}

.fs-xl {
  font-size: 24px;
}

.fs-lg {
  font-size: 20px;
}

.fs-md {
  font-size: 16px;
}

.fs-sm {
  font-size: 14px;
}

.fs-xs {
  font-size: 12px;
}

.fs-xxs {
  font-size: 10px;
}

h1.large {
  /* font-size:3.75rem; */
  line-height: 1.2;
}

h1 {
  /* font-size:3rem; */
  /* line-height:1.25; */
}

h2 {
  /* font-size:2.25; */
  line-height: 1.3333333333333333;
}

h3 {
  /* font-size:1.5; */
  line-height: 1.5;
}

h4 {
  /* font-size:1rem; */
  line-height: 1.5;
}

.text-italic {
  font-style: italic;
}

.highlight {
  color: var(--theme);
}

.bold-font {
  font-weight: bold;
}

.semi-bold-font {
  font-weight: 600;
}

/* Button Styles */
.btn {
  padding: 0.6875rem 0.9375rem;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px !important;
  transition: background-color 0.24s ease 0s, border-color 0.24s ease 0s, outline-color 0.24s ease 0s, color 0.24s ease 0s;
}

.btn:focus,
.btn:hover,
.btn:active {
  /* box-shadow: 0 0 0 0.2rem var(--theme); */
}

.btn span {
  vertical-align: middle;
}


.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
  /* box-shadow: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-color: var(--theme);
  border-width: 0.2rem;
  outline: none; */
}

.btn-theme,
.btn-theme:active,
.btn-theme:focus {
  /* background-color: var(--theme); */
  /* border: 1px solid var(--theme) !important;
  color: var(--white) !important; */

  border: solid 2px black;
  color: black;
  background: white;
  padding: 0.75rem 2rem;
  border-radius: 5px !important;
}
.btn-dark,
.btn-dark:active,
.btn-dark:focus {
  /* background-color: var(--theme); */
  /* border: 1px solid var(--theme) !important;
  color: var(--white) !important; */

  border: solid 2px black;
  color: white;
  background: black;
  padding: 0.75rem 2rem;
  border-radius: 5px !important;
}
.listed-event-cta .btn-theme {
  border: solid 2px white;
  color: white;
}

.listed-event-cta .btn-theme svg {
  fill: white !important;
  stroke: white !important;
}

.btn-theme svg {
  fill: black !important;
  stroke: black !important;
}

.btn-theme:hover {
  /* background-color: var(--dark-grey) !important;
  border-color: var(--dark-grey) !important;
  color: var(--white) !important;
   */
  color: red !important;
  border-color: red !important;
}

.btn-theme:hover svg {
  fill: red !important;
  stroke: red !important;
}
.btn-dark svg {
  fill: black !important;
  stroke: black !important;
}
/* .btn-dark:hover {

  color: red !important;
  border-color: red !important;
} */

.btn-dark:hover svg {
  fill: white !important;
  stroke: white !important;
}

.btn-clear,
.btn-clear:active,
.btn-clear:focus {
  background-color: var(--clear);
  border: 1px solid var(--white) !important;
  color: var(--white) !important;
}

.btn-clear svg {
  fill: var(--white) !important;
  stroke: var(--white) !important;
}

.btn-clear:hover {
  background-color: var(--clear) !important;
  border-color: var(--theme) !important;
  color: var(--theme) !important;
}

.btn-clear:hover svg {
  fill: var(--theme) !important;
  stroke: var(--theme) !important;
}

.btn-default,
.btn-default:active,
.btn-default:focus {
  background-color: var(--dark-grey);
  border: 1px solid var(--dark-grey) !important;
  color: var(--white) !important;
}

.btn-default:hover {
  background-color: var(--theme) !important;
  border-color: var(--theme) !important;
  color: var(--white) !important;
}

.btn-default svg {
  fill: var(--white) !important;
  stroke: var(--white) !important;
}

.btn-default:hover svg {
  fill: var(--white) !important;
  stroke: var(--white) !important;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--white) !important;
  border: 1px solid var(--white) !important;
  color: var(--black) !important;
}

.btn-primary svg {
  fill: var(--black) !important;
  stroke: var(--black) !important;
}

.btn-primary:hover {
  background-color: var(--theme) !important;
  border-color: var(--theme) !important;
  color: var(--white) !important;
}

.btn-primary:hover svg {
  fill: var(--white) !important;
  stroke: var(--white) !important;
}

  .btn-default svg {
    fill:var(--white) !important;
    stroke:var(--white) !important;
  }
  .btn-default:hover svg {
    fill:var(--white) !important;
    stroke:var(--white) !important;
  }
  .btn-alt,.btn-alt:active,.btn-alt:focus {
    background-color: var(--light-grey);
    border: 1px solid var(--light-grey) !important;
    color: var(--near-black) !important;
  }
  .btn-alt svg {
    fill:var(--near-black) !important;
    stroke:var(--near-black) !important;
  }
  .btn-alt:hover{
    background-color: var(--dark-grey) !important;
    border-color: var(--dark-grey) !important;
    color:var(--white) !important;
  }
  .btn-alt:hover svg {
    fill:var(--white) !important;
    stroke:var(--white) !important;
  }
 .btn-primary,.btn-primary:focus, .btn-primary:hover, .btn-primary:active {
    background-color: var(--white) !important;
    border: 1px solid var(--white) !important;  
    color:var(--black) !important;
  }
  .btn-primary svg {
    fill:var(--black) !important;
    stroke:var(--black) !important;
  }
  .btn-primary:hover {
    background-color: var(--theme) !important;
    border-color: var(--theme) !important;
    color:var(--white) !important;
  }
  .btn-primary:hover svg {
    fill:var(--white) !important;
    stroke:var(--white) !important;
  }
/* Navigation */
#dashboard-layout .navbar {
  background-color: var(--theme) !important;
}

#main-layout .navbar-toggler {
  z-index: 10;
}

#main-layout .navbar-toggler.fixed {
  position: fixed;
  right: 40px;
}

#main-layout .dropdown-menu {
  position: absolute !important;
  top: 78px !important;
  left: -259px;
  right: 0;
  text-align: center;
  border-radius: 0;
  border: none;
  /* max-width:100%; */
  width: 100vw;
  z-index: 9;
}

/* Forms */
.form-group {
  margin-bottom: 1rem;
}

.form-control {
  border-radius: 0;
  height: calc(1.5em + .75rem + 2px);
}

input[type="number"],
select.commerce-item {
  width: 100px;
}

.clickableRow {
  transform: rotate(0);
}

.editRow {
  cursor: pointer;

}

/* Input Styles */
input:checked~.checkmark {
  background-color: var(--theme);
}

label {
  /* font-family: 'SemiBoldFont', Helvetica, Arial, sans-serif; */
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}

#dashboard-layout label {
  font-size: 14px;
}

.btn-link,
.btn-link:hover,
btn-link:visited {
  text-decoration: none;
}

a,
a:hover,
.a:focus,
a:active,
a:visited(:not(.btn)),
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link:visited {
  color: var(--theme);
}

#events .card {
  min-height: 506px;

}
.card-header {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  cursor:pointer;
}
.card-header span {

  font-size:0.75rem;
}
.card-header span.active {
  font-weight:700;
}

.flex-card {
  flex: 1;
}

#events .card h4 {
  font-size: 1.25rem;
}

.card-img,
.card-img-top {
  border-radius: 0;
  height: 170px;
  object-fit: cover;
}

.card-img,
.card-img-top.inactive {
  opacity: 0.1;
}

.crm-verified {
  color: var(--success);
}

.crm-pending {
  color: var(--error);
}

/* .card-body {
  min-height:200px;
} */
.card-active {
  border: 4px solid var(--theme);
}

#dashboard-layout .checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}

#main-layout .checkbox-container {
  display: block;
  position: relative;
  margin-bottom: 0.25rem;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background-color: var(--dark-grey);
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
  background-color: var(--dark-grey);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: var(--theme);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cookie-container {
  position: relative;
  background: var(--dark-grey);
  color: var(--light-grey);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 50px;
  padding-left: 15px;
  font-size: 14px;
}

.cookie-close {
  font-family: sans-serif;
  position: absolute;
  right: 15px;
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
  transform: translateY(-50%);
  top: 50%;
  background: var(--dark-grey);
  padding: 8px 14px;
}

.covid-banner {
  text-align: center;
  background: var(--theme);
  padding: 12px 0;
  color: var(--white);
  font-size: 18px;

}

.covid-banner a,
.covid-banner a:hover,
.covid-banner a:focus,
.covid-banner a:visited {
  color: var(--white) !important;
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

#header {
  /* height: 117px; */
  height: 80px;
  border-bottom: 1px solid var(--light-grey);
  font-size: 16px;
}

#header .container-xxl {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  z-index: 999;
}

.header-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 233px;
  height: auto;
  /* background: var(--white); */
  z-index: 10;
  padding-top: 28px;
}

.header-logo img {

  width: 100%;
}

#main-layout .navbar {
  /* margin-left: 150px; */
  /* padding-top: 38.5px; */
  /* height:116px; */
  height: 80px;

}

#main-layout #subnav .navbar {
  margin-left: 0;
  background: var(--dark-grey);
  color: var(--white);
  height: auto;
}

#main-layout .nav-item {
  height: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 3px solid transparent;
}

#main-layout #subnav .nav-item {
  height: auto;
  cursor: pointer;
}

#main-layout .nav-item a,
#main-layout .dropdown-item a {

  color: var(--black) !important;
}

#main-layout .dropdown-item.active,
#main-layout .dropdown-item:active {
  background-color: var(--white) !important;
  outline: none;
}

#main-layout #subnav .nav-item a {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: var(--white) !important;
}

#main-layout .nav-item:hover {
  border-bottom: 3px solid var(--theme);
  background: var(--white);
  /* padding-bottom: 38.75px; */
}

#main-layout #subnav .nav-item:hover {
  border-bottom: none !important;
  background: inherit !important;
  /* padding-bottom: 38.75px; */
}

#main-layout .nav-item:hover a {
  color: var(--theme) !important;
}

#main-layout .nav-item:hover a svg {
  fill: var(--theme) !important;
}

#main-layout .nav-item:hover .dropdown-item a {
  color: var(--black) !important;
}

#main-layout .nav-item:hover .dropdown-item a svg {
  fill: var(--black) !important;
}

#main-layout .nav-item:hover .dropdown-item a:hover {
  color: var(--theme) !important;
}

#main-layout .nav-item:hover .dropdown-item a:hover svg {
  fill: var(--theme) !important;
}

.dealer-nav .nav-link {
  border-left: 1px solid var(--light-grey);
}

#main-layout .footer-light a,
.footer-light a:active,
.footer-light a:visited {
  color: var(--black);
}

#main-layout .footer-light .footer-text svg {
  fill: var(--theme) !important;
}

#main-layout .footer-light a:hover,
.footer-light a:focus {
  color: var(--theme);
}

#main-layout .footer-dark {
  background: var(--black);
  color: var(--white);
}

#main-layout .footer-dark hr {
  background: var(--med-grey);
  ;
}

#main-layout .footer-dark a,
.footer-light a:active,
.footer-light a:visited {
  color: var(--white);
}

#main-layout .footer-dark a:hover,
.footer-light a:focus {
  color: var(--theme);
}

#main-layout .main-container {
  /* min-height:100vh;
  min-height:calc(100vh - 92px); */

  /* max-width: 2000px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  width: 100%;
  /* row-gap: 50px; */
}

.grid-container {
  /* max-width: 2000px; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  width: 100%;
  /* row-gap: 50px; */
}
.desktop-image {
  width:800px;
  aspect-ratio:80/29;
  object-fit: cover;
}
.mobile-imabe {
  width:400px;
  aspect-ratio:4/3;
  object-fit: cover;
}
.modal-dialog {
  max-width: 830px !important;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #0000FF;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.hover-row:hover {
  background-color: var(--error-light);
}


i {
  display: flex;
}

.footer svg {
  fill: var(--white);
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 1rem;

}

.footer .social-icon {
  background: var(--near-black);
  width: 46px;
  height: 46px;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
}

.footer .social-icon svg {
  width: 100%;
  height: 100%;
}

.footer-fp>span {
  margin-top: 4px;
}

.footer-link {
  text-decoration: none;
  /* font: 400 1rem / calc(2.125ex + 6px) "Porsche Next", "Arial Narrow", Arial, "Heiti SC", SimHei, sans-serif; */
  font-size: 1rem / calc(2.125ex + 6px);
}

.footer-link:hover {
  color: var(--white) !important;
  background-color: #212225;
}

.footer .social-icon.tw:hover {
  background: rgb(29, 161, 242);
}

.footer .social-icon.ig:hover {
  background: rgb(225, 48, 108);
}

.footer .social-icon.fb:hover {
  background: rgb(24, 119, 242);
}

.footer .social-icon.yt:hover {
  background: rgb(255, 0, 0);
}

.footer .social-icon.p:hover {
  background: rgb(230, 0, 35);
}

.footer .social-icon.li:hover {
  background: rgb(0, 119, 181);
}

.footer .social-icon svg {
  /* width: 24px; */
  fill: #FFFFFF;
}

.check-in-container {
  min-height: calc(100vh - 200px);
}

@media (min-width: 992px) {
  #main-layout .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  #main-layout .dropdown-menu {
    left: calc((((100vw - 960px)/2) + 189px) * -1);
  }
}

@media (min-width: 1200px) and (max-width: 1366px) {
  #dashboard-layout #main .container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {

  #main-layout .dropdown-menu {
    left: calc((((100vw - 1170px)/2) + 189px) * -1);
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px !important;
  }

  #main-layout .dropdown-menu {
    left: calc((((100vw - 1340px)/2) + 189px) * -1);
  }
}

@media (min-width: 1600px) {
  #main-layout header {
    font-size: 20px;
  }

  .container {
    max-width: 1540px !important;
  }

  #main-layout .dropdown-menu {
    left: calc((((100vw - 1540px)/2) + 189px) * -1);
  }

}

@media (max-width: 1199px) {
  .header-logo {
    width: 140px;
    /* padding-top:16px; */
  }
}

@media (max-width: 991px) {

  /* header {
    height:96px;
  }
  #main-layout .navbar {
    height:95px;
  } */
  .header-logo {
    width: 140px;
    /* padding-top:16px; */
  }

  #main-layout .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem !important;
    padding-left: 0, 5rem !important;
  }

  #basic-navbar-nav {
    position: fixed;
    background: var(--white);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
  }

  #main-layout .nav-item {
    height: auto;
  }

  #main-layout .navbar-toggler {
    margin: 0 0 0 auto;
    z-index: 11;
  }

  #main-layout .nav-item {
    margin: 0 auto;
  }

  .fs-xxxl {
    font-size: 42px !important;
  }

  .fs-xx-l {
    font-size: 36px !important;
  }

  .fs-xxl {
    font-size: 28px !important;
  }

  .fs-xl {
    font-size: 20px !important;
  }

  .fs-lg {
    font-size: 20px;
  }

  .fs-md {
    font-size: 16px;
  }

  .fs-sm {
    font-size: 14px;
  }

  .fs-xs {
    font-size: 12px;
  }

  .fs-xxs {
    font-size: 10px;
  }

}

@media(max-width:767px) {
  .awssld__next {
    visibility: hidden !important;
  }

  .awssld__prev {
    visibility: hidden !important;
  }

  .fs-xxxl {
    font-size: 34px !important;
  }

  .fs-xx-l {
    font-size: 28px !important;
  }

  .fs-xxl {
    font-size: 24px !important;
  }

  .fs-xl {
    font-size: 18px !important;
  }

  .fs-lg {
    font-size: 20px;
  }

  .fs-md {
    font-size: 16px;
  }

  .fs-sm {
    font-size: 14px;
  }

  .fs-xs {
    font-size: 12px;
  }

  .fs-xxs {
    font-size: 10px;
  }
}

@media(max-width:575px) {
  .fs-xxxl {
    font-size: 28px !important;
  }

  .fs-xx-l {
    font-size: 24px !important;
  }

  .fs-xxl {
    font-size: 20px !important;
  }

  .fs-xl {
    font-size: 16px !important;
  }

  .fs-lg {
    font-size: 16px !important;
  }

  .fs-md {
    font-size: 16px;
  }

  .fs-sm {
    font-size: 14px;
  }

  .fs-xs {
    font-size: 12px;
  }

  .fs-xxs {
    font-size: 10px;
  }
}

.small-container {
  max-width: 1170px !important;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--black-overlay);
}

.featured-image-container {
  width: 100vw;
  max-width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.featured-image {
  max-width: 100vw;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.featured-image-details {
  /* font-family: 'SemiBoldFont', Helvetica, Arial, sans-serif; */
  font-weight: 600;

  position: absolute;
  /* background:var(--near-black); */
  color: var(--white);
  z-index: 3;
  bottom: 10%;
  left: 0;
  width: 100%;
}

.featured-image-details a {
  color: var(--white) !important;

}

.featured-image-details a:hover {
  color: var(--theme) !important;
  text-decoration: none;

}

.logo-image {
  max-width: 224px;
  max-height: 224px;
}

.time-btn {
  min-width: 100px;
}
.condensed .time-btn {
  min-width:180px;
}
.pointer {
  cursor: pointer;
}

.no-line-height {
  line-height:1;
}
.cc-img {
  aspect-ratio: 3/2;
  object-fit: cover;
}
.cc-img-w {
  aspect-ratio: 2/1;
  object-fit: cover;
}
input[type=radio] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}
.radio-label {
  font-weight:normal;
  font-family: 'RegularFont' !important;;
}
.invalid-input {
  border-color:var(--error) !important;
  border-width:2px;
}
.auth-input {
  max-width:320px;
}