/* layout styles */
/* #dashboard-layout nav {
  background: var(--theme);
} */
#dashboard-layout nav .brand {
  font-size: 1.5rem;
  margin-left: 1.5rem;
}
#dashboard-layout .navbar-brand span {
  font-weight: bold;
}

/* form styles */
#dashboard-layout form .input-field {
  margin: 2rem 0;
}
.sidebar {
  background: var(--theme-sidebar);
  min-height: 100vh;
  box-shadow:inset -1px 0 0 rgba(0, 0, 0, .1)
}
.sidebar,
#dashboard-layout #main {
  padding-top: 80px;
}
#dashboard-layout #main .container:first-child {
  margin-left: 0 !important;
}
#dashboard-layout nav i {
  cursor: pointer;
}
#dashboard-layout a.nav-link,#dashboard-layout a.nav-link a.nav-link:hover, #dashboard-layout a.nav-link a.nav-link:focus,#dashboard-layout a.nav-link a.nav-link:active, #dashboard-layout a.nav-link a.nav-link:visited {
  color:var(--black);
  text-decoration:none;
}
#dashboard-layout #nav a.nav-link,#dashboard-layout #nav a.nav-link a.nav-link:hover, #dashboard-layout #nav a.nav-link a.nav-link:focus,#dashboard-layout #nav a.nav-link a.nav-link:active, #dashboard-layout #nav a.nav-link a.nav-link:visited {
  color:var(--white);
  text-decoration:none;
}
@media (max-width: 1199px) {
  .sidebar {
    position: absolute !important;
    left: 0;
    z-index: 1;
  }

  .sidebar-titles-hide {
    display: none !important;
  }
  .sidebar-titles-show {
    min-width: 160px;
  }
  #dashboard-layout #main {
    padding-left: 64px !important;
  }
}
@media (max-width: 767px) {
  .sidebar-hide {
    display: none !important;
  }

  #dashboard-layout .nav-link {
    padding: 0.5rem 0.5rem !important;
  }
  #dashboard-layout #main {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 64px;
  }
}
