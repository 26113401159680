.covid-banner {
    text-align: center;
    background: var(--theme);
    padding: 12px 0;
    color: var(--white);
    font-size: 18px;

}

.covid-banner a,
.covid-banner a:hover,
.covid-banner a:focus,
.covid-banner a:visited {
    color: var(--white) !important;
    text-decoration: underline;
}

#main-layout .mobile-banner {
    display: none;
}

@media (max-width: 767px) {
    .mobile-banner {
        display: block;
        object-fit: cover;
        width: 100vw;
        height: 75vw;
    }

    .desktop-banner {
        display: none;
    }
}

.listed-event-container {
    position: relative;
    z-index: 1;
}

.listed-event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: var(--black-overlay);
}

.listed-event-details {
    position: absolute;
    /* top: 1.25rem;
    left: 1.75rem; */
    z-index: 3;
    color: var(--white);
    font-family: 'BoldFont', Helvetica, Arial, sans-serif;

}

/* .listed-event-cta {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 3;
} */

.featured-image-container {
    width: 100vw;
    height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
}

.reg-container .featured-image-container {
    max-width: 1600px;
    margin: 0 auto;
}

.featured-image {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.featured-image-details {
    font-family: 'BoldFont', Helvetica, Arial, sans-serif;

    position: absolute;
    /* background:var(--near-black); */
    color: var(--white);
    z-index: 3;
    bottom: 10%;
    left: 0;
    width: 100%;
}

/* .awssld__container {
    padding-bottom: 49%;
} */

.awssld {
    --organic-arrow-thickness: 3px;
    --organic-arrow-height: 16px;
    --slider-height-percentage: 47% !important;
    --loader-bar-color: var(--theme) !important;
    --loader-bar-height: 6px;
    --control-button-width: 40px !important;
    --control-button-height: 40px !important;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: var(--white) !important;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: var(--black) !important;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --control-bullet-active-color: #6a6a6a;
    --content-background-color: #2f2f2f;
}

.controls-off .awssld__controls {
    display: none;
}

.awssld__bullets button {
    width: 10px;
    height: 10px;
    background: var(--white);
}

.awssld__bullets--active {
    transform: scale(1) !important;
    border: 1px solid var(--white) !important;
    background: var(--theme) !important;
}

.awssld__next {
    right: 0.5rem !important;
}

.awssld__prev {
    left: 0.5rem !important;
}

.awssld__next:hover.awssld__prev:hover {
    background: var(--theme) !important;
    color: var(--white) !important;
}

.awssld__bullets {
    bottom: 0.5rem !important;
    z-index: 3;
}

.embed-container {
    width: 100%;
    height: 56.25vw;
    max-height: 849.375px;
}

.embed-container iframe {
    width: 100%;
    height: 56.25vw;
    border: none;
    max-height: 849.375px;
}

.input-group-append .btn {
    padding: 0 0.5rem !important;
}

input {
    border: 1px solid red;
}

#customCard-container input {
    border: 1px solid red;
}

.item-container {
    /* border:1px solid red; */
    aspect-ratio:4/3;
    height:180px;
}

.item-container img {

    object-fit:cover;
    width:100%;
}
.condensed {
    display: flex!important;
flex-wrap: wrap;
gap: 0 1.5rem;
}
.accepted-files svg {
    width:1.5rem;
    cursor:pointer;
}
.link {
    cursor: pointer;
    font-weight:bold;
}