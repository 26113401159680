.form-help {
    cursor:pointer;
}
#qr-code,#qr-code-reg, #qr-code-gallery {
    max-width:128px;
}
#qr-code canvas,#qr-code-reg canvas, #qr-code-gallery canvas {
    width:100% !important;
    height:auto !important;
}
.banner-image {
    /* height:43.25%; */
}
#dashboard-layout   .mobile-banner img {
    max-width:400px;
}
.copy-container {
    padding:0 5px;
}
.edit-copy {
    border: 1px dashed var(--light-grey);
}
.del-icon {
    cursor:pointer;
}
.gallery-image-container {
    border:1px solid var(--light-grey);
    margin:3px;
    padding:3px;
}
.gallery-image {
    height:200px;
    object-fit:cover;
}
.listed-container {
    border:1px solid var(--light-grey);
    padding:8px;
    background-color:var(--xlight-grey);
}
.cta-list-item {
    padding:5px;
    border:1px solid var(--light-grey);
}
.alt-row {
    background-color:var(--xlight-grey);
}
.floating-save {
    position:fixed;
    right: 2rem;
    bottom:2rem;
}